<!--
 * @Author: '张星' you@example.com
 * @Date: 2023-12-12 12:27:56
 * @LastEditors: '张星' you@example.com
 * @LastEditTime: 2024-07-03 18:58:29
 * @FilePath: \dataview-viewer-test\src\views\login\szsw\components\fuwuagreement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="wrapper">
    <div>
      <div  style="padding: 0px 16px">
        <p class="name">1. 用户协议的接受</p>
        <ul>
          <li>
            .
            使用或访问本服务即表明您确认同意这些条款。如果您不同意本服务条款及/或随时对其的修改，您应不使用或主动取消重庆市筑云科技有限责任公司（以下简称“筑云公司”）提供的服务。您的使用行为将被视为您对本服务条款全部的接受，包括接受BIMCC对服务条款随时所做的任何修改。
          </li>
          <li>
            .
            BIMCC将不断推出新的服务，本服务条款适用于BIMCC提供的各种服务，但当您使用BIMCC某一特定服务时，如该服务另有单独的服务条款、指引或规则，您应遵守本服务条款及BIMCC随时公布的与该服务相关的服务条款、指引或规则等。前述所有的指引和规则，均构成本服务条款的一部分。除非本服务条款另有其它明示规定，新推出的产品或服务、增加或强化目前本服务的任何新功能，均受到本服务条款的规范。
          </li>
        </ul>
      </div>
      <div  style="padding: 0 16px;margin-top: 16px">
        <p class="name">2. 服务条款的修改</p>
        <ul>
          <li>
            . 筑云公司有权在必要时修改本服务条款,
            服务条款一旦发生变动，将会在相关页面上公布修改后的服务条款。如果不同意所改动的条款，用户应主动取消此项服务。如果用户继续使用本服务，则视为接受服务条款的变动。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FuwuAgreement',
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
}
</script>
<style lang="less" scoped>
.name{
      font-size: 15px;
    font-weight: 500;
    color: #25282e;
}
.wrapper {
  p {
    line-height: 32px;
  }
  li {
    line-height: 28px;
  }
}
</style>
