<template>
  <div class="register-main" v-loading='registerLoading'>
    <div class='back' @click='back'> <i class='iconfont icona-zuojiantouyuanjiaoL'></i>返回登录</div>
    <div style="width: 100%; text-align: center; padding: 32px 0"> {{rePassWord?'忘记密码':'注册账号'}}</div>
    <el-form ref='registerForm'  :model="registerForm" :rules="rules">
      <el-form-item
        prop="phone"
        style="
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          width: 80%;
          margin: 0 auto;
        "
      >
        <el-input
          autocomplete="phone"
          v-model="registerForm.phone"
          placeholder="手机号"
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item
        prop="code"
        style="
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          width: 80%;
          margin: 0 auto;
        "
      >
        <div class="code-item">
          <el-input
            v-model="registerForm.code"
            placeholder="输入短信验证码"
            clearable
          ></el-input>
          <a
            href="javascript::"
            class="get-code"
            @click="onGetCode"
            :class="{
              disabled: +codeStatus === 1 || +codeStatus === 3,
            }"
          >
            <!--  验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时) -->
            <template v-if="+codeStatus === 0">获取验证码</template>
            <template v-else-if="+codeStatus === 1">获取中...</template>
            <template v-else-if="+codeStatus === 2">重新获取</template>
            <template v-else-if="+codeStatus === 3"
              >{{ countDown }}秒后可重发</template
            >
          </a>
        </div>
      </el-form-item>
      <el-form-item
        v-if ='!rePassWord'
        prop="name"
        autocomplete="new-password"
        style="
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          width: 80%;
          margin: 0 auto;
        "
      >
        <el-input v-model="registerForm.name" placeholder="您的姓名" clearable>
        </el-input>
      </el-form-item>
      <el-form-item
        autocomplete="new-password"
        prop="password"
        style="
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          width: 80%;
          margin: 0 auto;
        "
      >
        <el-input
          v-model="registerForm.password"
          placeholder="密码"
          clearable
          show-password
        >
        </el-input>
      </el-form-item>
      <el-form-item
        prop="confirmPassword"
        style="
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          width: 80%;
          margin: 0 auto;
        "
      >
        <el-input
          autocomplete="new-password"
          v-model="registerForm.confirmPassword"
          placeholder="重复密码"
          show-password
          clearable
        >
        </el-input>
      </el-form-item>
      <el-form-item
        prop="remark"
        v-if ='!rePassWord'
        style="
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);
          width: 80%;
          margin: 0 auto;
        "
      >
        <el-input v-model="registerForm.remark" placeholder="单位备注" clearable>
        </el-input>
      </el-form-item>
      <el-form-item style="width: 80%; margin: 0 auto">
        <el-button
          type="primary"
          @click="onRegister"
          style="border-radius: 8px; background: #1e9eff; width: 100%"
          >{{rePassWord?'确定提交':'注册'}} </el-button
        >
      </el-form-item>
    </el-form>
    <div style="font-size: 13px; margin: 0 auto; width: 80%" v-if ='!rePassWord'>
      <el-checkbox v-model="checked" style="color: rgba(255, 255, 255, 0.8)">
        我已阅读并同意 </el-checkbox
      ><span @click="aaa()" style="color: rgba(255, 255, 255, 0.8)"
        >《服务协议》</span
      ><span @click="userdialog = true" style="color: rgba(255, 255, 255, 0.8)">
        《隐私保护指引》</span
      >
    </div>

    <!-- 服务协议弹窗 -->
    <el-dialog :visible.sync="fuwudialog" title="服务协议"  :append-to-body="true"  width="50%">
      <FuwuAgreement/>
    </el-dialog>

    <el-dialog :visible.sync="userdialog" title="隐私保护指引" custom-class="user-agreement-dialog-custom-class" width="50%" :append-to-body="true">
      <UserAgreement/>
    </el-dialog>
   
  </div>
</template>

<script>
import { Checkbox } from 'element-ui'
import FuwuAgreement from './components/fuwuagreement.vue'
import UserAgreement from './components/useragreement.vue'
import { phoneSendMsg, dataInterface } from '@/apis/data/index';

export default {
  name: 'Register',
  components: { 'el-checkbox': Checkbox,
    FuwuAgreement,
    UserAgreement
  },
  props: {
    rePassWord:{
      type: Boolean,
      default:false,
    }
  },
  data() {
    const phoneReg =
      /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(19[0-9]{1})|(18[0-9]{1}))+\d{8})$/
    /* eslint-disable */
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入手机号码!'))
      }
      if (!phoneReg.test(value)) {
        return callback(new Error('请输入正确格式的手机号码!'))
      }
      callback()
    }
     const checkNewPassword = (rule, value, callback) => {
      const reg = /^(?=.*[0-9\!@#\$%\^&\*])(?=.*[a-zA-Z])(?=.*[.!@#$%^&*])(?!.*\s).{8,16}$/;
      if (!value) {
        callback(new Error('请输入密码!'));
      } else if (!reg.test(value)) {
        callback(new Error('密码由数字、字母、特殊字符组成,长度8-16位,必须包含数字和字母!'))
      } else {
        callback();
      }
    }

    const checkConfirmPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请确认密码!'));
      } else if (value !== this.registerForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
      test:true,
      registerForm: {
        phone: '',
        code: '',
        name: '',
        password: '',
        confirmPassword: '',
        remark: '',
      },
      rules: {
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: checkNewPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { validator: checkConfirmPassword, trigger: 'blur' }
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        // name: [{ required: true, message: '请输入您的姓名', trigger: 'blur' }],
        phone: [{ validator: checkPhone, trigger: 'blur' }],
        remark: [{ required: true, message: '请输入单位备注', trigger: 'blur' }],
      },
      checked: false,

      codeStatus: 0,
      phoneReg,
      checkNewPassword,

      fuwudialog: false,
      userdialog: false,

      // 验证码获取状态 0 从未获取 1 获取中 2  已获取已过期(重新获取) 3 已获取未过期(倒计时)
      codeStatus: 0,
      // 倒计时
      countDown: 0,
      // 结束时间戳
      endTime: 0,
      // 计时器对象
      interval: null,
      registerLoading:false
    }
  },
  watch: {},
  computed: {},
  methods: {
    aaa(){
      this.fuwudialog = true
      console.log( this.fuwudialog);
    },
    back(){
      this.$emit('onReset')
    },
    onRegister() {
       this.$refs.registerForm.validate((valid) => {
        if (valid) {
          if(this.rePassWord){
            dataInterface({
              phone: this.registerForm.phone,
              code: this.registerForm.code,
              password: this.registerForm.password,
              password_confirmation: this.registerForm.confirmPassword
            },'api/v4/user/reset/pwd')
            .then((res) => {
              if (res.data.code == 200) {
                this.$message.success('提交成功！即将返回登录框')
                setTimeout(() => {
                  this.$emit('onReset')
                }, 1000);
              }else{
                this.$message.error(res.data.msg)
              }
          });
          }else{
            if(!this.checked){
              this.$message.error('请先同意隐私协议！')
              return 
            }
            this.registerLoading = true
            const params = {
              __method_name__: 'createData',
              object_uuid: 'object62d902e1345a8',
              view_uuid: 'view62d905f15976b',
              transcode: 0,
              phone: this.registerForm.phone,
              name: this.registerForm.name,
              password: this.registerForm.password,
              remark: this.registerForm.remark,
              code: this.registerForm.code
            }
            dataInterface(params).then((res) => {
              this.$message.success('注册已提交到后台，审核成功后可登录！')
              this.registerLoading = false
            })
          }

        }
      })
    
    },
    onGetCode() {
      if (this.codeStatus === 1) {
        this.$message.warning('验证码获取中...')
        return
      }
      if (this.codeStatus === 3) {
        this.$message.warning('请勿重复获取验证码!')
        return
      }
      if (
        !this.registerForm.phone ||
        !this.phoneReg.test(this.registerForm.phone)
      ) {
        this.$message.error('请输入正确格式的手机号码！')
        return false
      }
      this.codeStatus = 1
      // console.log('获取');
      if(this.rePassWord){
        dataInterface({phone: this.registerForm.phone}, 'api/v4/user/reset/sendcode').then((res) => {
          if (res && res.status === 200) {
            this.$message.success('获取成功')
            this.codeStatus = 3
            this.endTime = new Date().getTime() + 60000 * 5
            this.setCountDown()
            sessionStorage.setItem('codeTime', this.endTime)
          }
        })
        .catch(() => {
          this.$message.error('验证码获取失败！')
          this.codeStatus = 0
        })
      }else{
        phoneSendMsg({
        phone: this.registerForm.phone,
        prefix: 'register',
      })
        .then((res) => {
          if (res && res.status === 200) {
            this.$message.success(res.data.data.msg)
            this.codeStatus = 3
            this.endTime = new Date().getTime() + 60000 * 5
            this.setCountDown()
            sessionStorage.setItem('codeTime', this.endTime)
          }
        })
        .catch(() => {
          this.$message.error('验证码获取失败！')
          this.codeStatus = 0
        })
      }
    },
    /**
     * @desc: 倒计时
     */
    setCountDown() {
      const now = new Date().getTime()
      if (this.endTime && now > this.endTime) {
        this.codeStatus = 2
        return
      }
      this.countDown = Math.floor((this.endTime - now) / 1000)
      this.interval = setInterval(() => {
        if (this.countDown <= 0) {
          this.codeStatus = 2
          clearInterval(this.interval)
          sessionStorage.removeItem('codeTime')
          return
        }
        this.countDown--
      }, 1000)
    },
  },
  created() {},
  mounted() {},
}
</script>
<style lang="less">
@inputHeight: 40px;
@baseFont: 14px;
@baseColor: rgb(105, 138, 179);
@baseBg: rgb(236, 242, 252);

.register-main {
  position: absolute;
  top: 18%;
  font-size: 20px;
  font-weight: 500;
  right: 12%;
  width: 400px;
  color: #fff;
  padding: 10px 0  39px 0;
  background: rgba(21, 105, 171, 0.2);
  border-radius: 24px;
  backdrop-filter: blur(20px);
  .back{
    padding-left: 34px;
    padding-top: 17px;
    font-size: 14px;
    display: flex;
    align-items: center;
    i{
      margin-right:4px;
    }
  }
  .el-input {
    .el-input__prefix {
      left: 0px;
      top: 2px;
    }
    .el-input__prefix {
      color: rgba(255, 255, 255, 0.6);
    }
    .el-input__inner {
      background: transparent !important;
      border: none !important;
      color: #fff;
      font-size: 16px !important;
      padding: 0 3px !important;
    }
  }
  .el-form {
    .el-form-item {
      margin-bottom: 28px !important;
    }
  }
  .code-item {
    display: flex;
    .el-input {
      flex: 1;
    }
    .get-code {
      height: 24px;
      width: auto;
      min-width: 90px;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 10px 10px;
      color: #3d79a2 !important;
      text-align: center;
      background: rgba(255, 255, 255, 0.64);
      line-height: 8px;
      box-sizing: border-box;
      font-size: 13px;
      font-weight: 500;
      &.disabled {
        background: #dcdfe6;
        color: #fff;
        cursor: not-allowed;
      }
    }
  }
}
.user-agreement-dialog-custom-class {
  display: flex;
  flex-direction: column;
  height: 70%;
  .el-dialog__body {
    flex: 1;
    overflow-y: auto;
  }
}
</style>