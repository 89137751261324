import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=488f6d44&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=488f6d44&prod&lang=less&scoped=true"
import style1 from "./Index.vue?vue&type=style&index=1&id=488f6d44&prod&lang=less&scoped=true"
import style2 from "./Index.vue?vue&type=style&index=2&id=488f6d44&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "488f6d44",
  null
  
)

export default component.exports