<!--
 * @Description: 葛洲坝登录页
 * @Author: luocheng
 * @Date: 2022-03-21 14:31:09
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-05-09 14:45:49
-->
<template>
  <div class="gzb-login">

    <div class="wrap">
      <div class="wrapper">
        <div class="main">
          <div class="title">
            欢迎登陆
          </div>
          
          <el-form ref="loginForm" :model="loginForm" :rules="rules">

            <el-form-item prop="name">
              <el-input v-model="loginForm.name" placeholder="请输入账号" clearable>
                <i class="el-icon-s-home icon" slot="prefix"></i>
              </el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input v-model="loginForm.password" type="password" placeholder="请输入密码" clearable>
                <i class="el-icon-lock icon" slot="prefix"></i>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="onLogin">登录</el-button>
            </el-form-item>
          </el-form>

          <img src="@/assets/images/login/slogan.png" alt="标语" class="slogan">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '@/custom-component/login/mixin';

export default {
  name: 'GZBLogin',
  mixins: [mixin],
  data() {
    return {
      year: new Date().getFullYear(),
      loginForm: {
        name: '',
        password: ''
      },
      rules: {
        name: [{ required: true, message: '账号不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    /**
     * @desc: 登录
     */
    onLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$loading();
          try {
            this.doLogin({
              ...this.loginForm
            })
          } catch (err) {
            this.$loading().close();
            this.$message.error('登录失败，请联系管理员！');
          }
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.gzb-login {
  height: 100%;
  width: 100%;
  background-image: url('https://static.bimcc.com/images/cggcLoginBg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  animation: slideleft 20000s infinite linear;
  -webkit-animation: slideleft 20000s infinite linear;
  background-attachment: fixed;
  position: relative;
  height: 100%;

  .wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;

    .wrapper {
      border-radius: 10px;
      background-color: #fff;

      margin-right: 100px;

      .main {
        padding: 50px 50px 25px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
          color: var(--themeColor);
          font-size: 42px;
          font-weight: bold;
          letter-spacing: 2px;
          margin: 30px 0px 50px 0px;
          border-bottom: 7px solid #ccc
        }

        .slogan {
          width: 250px;
          padding-top: 50px;
        }

        .el-form-item {
          margin-bottom: 30px;

          .el-button {
            display: block;
            width: 100%;
            background: var(--themeColor);
            border: none;
            height: 34px;
            font-size: 14px;
            letter-spacing: 2px;

            &:hover {
              background: rgba(31, 104, 216, .8);
            }
          }


          :deep(.el-input)  {
            &__inner {
              height: 50px;
              line-height: 50px;
              width: 350px;
              font-size: 15px;
              font-weight: 600;
              padding-left: 40px;
            }

            &__prefix {
              display: flex;
              align-items: center;
              padding: 0 5px;

            }

            &__clear {
              font-size: 17px;
              display: flex;
              align-items: center;
            }

            .icon {
              font-size: 25px
            }
          }

        }

      }


    }
  }
}
</style>